<script setup lang="ts">
// Imports
import { onClickOutside, useElementBounding } from '@vueuse/core';
import { useField } from 'vee-validate';

export type Select = {
  key: string | number;
  label: string;
  icon?: string;
  iconClass?: string;
  disabled?: boolean;
};

// Props & Emits
const emits = defineEmits(['selectItem']);
const props = defineProps<{
  items: Select[];
  name: string;
  placeholder?: string;
  label?: string;
  hideDetails?: boolean;
  required?: boolean;
  icon?: string;
  disabled?: boolean;
  width?: number;
  listTitle?: string;
  noItemsSelectedTitle?: string;
  listSelectedTitle?: string;
  customAllSelectedItemsText?: string;
}>();

const button = ref<HTMLElement | null>(null);
const { left, width: buttonWidth, height, top, y, update } = useElementBounding(button);

const element = ref<HTMLElement | null>(null);
const { width: elementsWidth, height: elementsHeight, update: reload } = useElementBounding(element);

const inputContainer = ref<HTMLElement | null>(null);
onClickOutside(inputContainer, () => (open.value = false));

// Data
const { value, errorMessage } = useField<(string | number)[]>(props.name, {
  initialValue: [],
});
const open = ref(false);

// Computed
const elements = computed(() => {
  return props.items.filter((item) => !value.value?.includes(item.key));
});

const getWindowHeight = computed(() => {
  return window.innerHeight;
});
const getYPosition = computed(() => {
  if (getWindowHeight.value - y.value > elementsHeight.value + 32) {
    return `top: ${top.value + height.value}px;`;
  } else {
    return `top: ${y.value - elementsHeight.value - 16}px;`;
  }
});

// Methods
const clickItem = (item: Select) => {
  if (item.disabled || props.disabled) return;
  const el = value.value?.find((el) => el === item.key);
  if (el) {
    const index = value.value?.findIndex((el) => el === item.key);
    value.value.splice(index, 1);
  } else {
    if (!value.value) value.value = [];
    value.value.push(item.key);
  }
  emits('selectItem', value.value);
};

const getItem = (key: string | number) => {
  return props.items.find((item) => item.key === key);
};

const openMenu = () => {
  if (props.disabled) return;
  reload();
  update();
  open.value = !open.value;
};

const deleteElement = (index: number) => {
  value.value.splice(index, 1);
  emits('selectItem', value.value);
};
</script>

<template>
  <div class="w-full" :class="[hideDetails ? '' : 'mb-[16px]']">
    <label v-if="label" :class="errorMessage ? 'text-red-300' : 'text-gray-600'" class="font-medium text-xs mb-[6px] block text-left">
      {{ label }}
      <span v-if="required" class="text-red-500">*</span>
    </label>
    <div ref="inputContainer" class="w-full relative">
      <div class="relative w-full inline-block text-left">
        <div class="group cursor-pointer">
          <div
            v-if="!$slots.button"
            ref="button"
            :class="[
              errorMessage ? 'border-red-600' : 'border-gray-200  hover:ring-[1px]',
              open
                ? errorMessage
                  ? 'border-red-600 ring-red-100 ring-[2px]'
                  : 'border-primary-500 ring-green-100 ring-[2px]'
                : errorMessage
                  ? ''
                  : 'hover:border-gray-300 hover:ring-gray-100',
              disabled ? '!border-gray-100' : '',
              value?.length === 0 || !value ? 'justify-between' : 'justify-end',
            ]"
            class="flex relative z-0 h-[32px] bg-white items-center overflow-hidden w-full justify-between border rounded-md px-3 text-sm font-medium"
            @click="openMenu()"
          >
            <p v-if="value?.length === 0 || !value" class="text-sm text-gray-500 font-[400]">
              {{ placeholder ?? $t('input.select') }}
            </p>
            <div v-else-if="value.length === items.length">
              <ui-badge color="gray">{{ customAllSelectedItemsText ?? $t('global.all') }}</ui-badge>
            </div>
            <div v-else class="absolute right-0 top-0 left-0 bottom-0 px-3 flex items-center gap-x-2">
              <ui-badge v-if="value?.at(0)" color="gray">{{ items.find((item) => item.key === value.at(0))?.label }}</ui-badge>
              <ui-badge v-if="value?.at(1)" color="gray">{{ items.find((item) => item.key === value.at(1))?.label }}</ui-badge>
              <ui-badge v-if="value?.length > 2" color="gray">+ {{ value?.length - 2 }}</ui-badge>
            </div>
            <ui-icon class="right-2 h-4 w-4 absolute z-5 text-gray-600 group-hover:text-gray-700" name="ChevronDown" />
          </div>
          <div v-else>
            <slot name="button" />
          </div>
        </div>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-y-95"
          enter-to-class="transform opacity-100 scale-y-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-y-100"
          leave-to-class="transform opacity-0 scale-y-95"
        >
          <div
            v-if="open"
            ref="element"
            :style="`left: ${left - elementsWidth + buttonWidth}px; width: ${buttonWidth}px;};` + getYPosition"
            class="fixed max-h-[200px] origin-top z-[9999] bg-white overflow-y-auto mt-2 p-2 rounded-md shadow-lg border border-gray-200 focus:outline-none"
          >
            <div v-for="(item, index) in value" :key="index" class="space-y-2">
              <slot name="item" :item="item">
                <div
                  class="select-none cursor-pointer flex items-center justify-between"
                  :class="[
                    getItem(item)?.disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50',
                    'block px-2 py-1.5 text-sm rounded-md',
                  ]"
                  @click="deleteElement(index)"
                >
                  <div>
                    <component
                      :is="getItem(item)?.icon"
                      v-if="getItem(item)?.icon"
                      :class="getItem(item)?.iconClass"
                      class="w-5 h-5 mr-4 md:mr-2 md:w-4 md:h-4"
                    />
                    <p class="whitespace-nowrap text-gray-600">
                      {{ getItem(item)?.label }}
                    </p>
                  </div>

                  <ui-icon name="Check" class="w-4 h-4 text-gray-600" />
                </div>
              </slot>
            </div>
            <div v-for="item in elements" :key="item.key" :disabled="item.disabled" class="space-y-2">
              <slot name="item" :item="item">
                <div
                  class="select-none cursor-pointer flex items-center text-gray-600 px-2 py-1.5 text-sm rounded-md"
                  :class="[item.disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100', '']"
                  @click="clickItem(item)"
                >
                  <div>
                    <component :is="item.icon" v-if="item.icon" :class="item.iconClass" class="w-5 h-5 mr-4 md:mr-2 md:w-4 md:h-4" />
                  </div>

                  <p class="whitespace-nowrap">
                    {{ item.label }}
                  </p>
                </div>
              </slot>
            </div>
          </div>
        </transition>
      </div>
      <span v-if="errorMessage" class="absolute right-0 top-[34px] text-red-500 text-xs">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>
